import React, { Component } from 'react';
import TableKiosks from './TableEmployees';
import { Grid } from '@material-ui/core';
import DialogCreateKiosk from './DialogCreateEmployee';
import { getEmployees } from '../../../services/employee';

class Employees extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
		};
	}

	componentDidMount() {
		this.getData();
	}

	async getData() {
		try {
			const { employees } = await getEmployees();
			console.log('employees', employees);
			this.setState({ rows: employees });
		} catch (error) {
			console.error(error);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			this.getData();
		}
	}

	change = () => {
		this.getData();
	};

	render() {
		return (
			<div>
				<Grid container>
					<Grid item xs={12}>
						<TableKiosks
							rows={this.state.rows}
							change={this.change}
						/>
					</Grid>
					<DialogCreateKiosk change={this.change} />
				</Grid>
			</div>
		);
	}
}

export default Employees;
