import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { employeeDelete } from '../../../../../services/employee';

class FormEditEmployee extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.data.nameEmployeeKiosk,
			pin: '',
			nickName: this.props.data.nicknameEmployeeKiosk,
			error: {
				name: false,
				pin: false,
				nickName: false,
			},
		};
	}

	render() {
		const { handleClose, change } = this.props;

		const handleSubmit = async () => {
			const data = {
				id: this.props.data.id,
			};

			try {
				const response = await employeeDelete(data);
				if (!response) {
					throw new Error('No response');
				}
				handleClose();
				change();
			} catch (error) {
				console.error(error);
			}
		};

		return (
			<Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h4' color='textPrimary' >
                        ¿Está seguro de realizar esta acción?
                    </Typography>
                    <Typography variant='h5' color='textSecondary' >
                        Esta acción es irreversible
                    </Typography>
                </Grid>
				<Grid item container justify="flex-end">
					<Grid item>
						<div>{this.state.formError}</div>
					</Grid>
					<Grid item>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
					</Grid>
					<Grid item>
						<Button
							onClick={handleSubmit}
							variant="contained"
							color="primary"
						>
							Aceptar
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default FormEditEmployee;
