import { SERVER_BASE_URL_KIOSK } from '../constants';

export const getOrders = async function (data) {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}sales`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();

		return json;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const firstPayment = async (idSelling, payment, tokenEmployee) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}firstPayment`, {
			method: 'POST',
			body: JSON.stringify({ idSelling, payment }),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
				tokenEmployee,
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			return json;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const addPayment = async (idSelling, payment, tokenEmployee) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}addPayment`, {
			method: 'POST',
			body: JSON.stringify({ idSelling, payment }),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
				tokenEmployee,
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			return json;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const updateStatus = async function (status, idSelling) {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}updateSale`, {
			method: 'POST',
			body: JSON.stringify({ status, idSelling }),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			return json;
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};
