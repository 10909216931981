import { combineReducers } from "redux";
import isLogged from './isLogged'
import menuReducer from "./menu";
import employee from './employee'

const reducer = combineReducers({
    isLogged,
    employee,
    menu: menuReducer
})

export default reducer