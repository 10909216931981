import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { columns } from '../../../../services/employee';
import { Grid, Button } from '@material-ui/core';
import KioskIcon from '@material-ui/icons/Storefront';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogUpdateEmployee from './DialogUpdateEmployee';
import DialogDeleteEmployee from './DialogDeleteEmployee';

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
	tableWrapper: {
		maxHeight: 440,
		overflow: 'auto',
	},
	title: {
		fontSize: 30,
		fontWeight: 'bold',
	},
	subtitles: {
		fontWeight: 'bold',
	},
});

export default function TableEmployees({ rows, change }) {
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(8);
	const [editEmployee, setEditEmployee] = React.useState({
		open: false,
		selectedRow: {},
	});
	const [deleteEmployee, setDeleteEmployee] = React.useState({
		open: false,
		selectedRow: {},
	});
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const handleClickUpdate = (selectedRow) => () => {
		setEditEmployee({ selectedRow, open: true });
	};
	const handleClickDelete = (selectedRow) => () => {
		setDeleteEmployee({ selectedRow, open: true });
	};

	const handleOnCloseUpdate = () => {
		setEditEmployee({ ...editEmployee, open: false });
	};
	const handleOnCloseDelete = () => {
		setDeleteEmployee({ ...deleteEmployee, open: false });
	};

	return (
		<Grid container>
			<DialogUpdateEmployee
				open={editEmployee.open}
				handleClose={handleOnCloseUpdate}
				change={change}
				row={editEmployee.selectedRow}
			/>
			<DialogDeleteEmployee
				open={deleteEmployee.open}
				handleClose={handleOnCloseDelete}
				change={change}
				row={deleteEmployee.selectedRow}
			/>
			<Grid item xs={12}>
				<div className={classes.title}>
					<KioskIcon className={classes.title} />
					Usuarios
				</div>
				<br />
			</Grid>
			<Grid item xs={12}>
				<Paper className={classes.root}>
					<div className={classes.tableWrapper}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											key={column.id}
											align={column.align}
											style={{
												minWidth: column.minWidth,
											}}
										>
											{column.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows
									.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
									.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.id}
											>
												<TableCell align={row.align}>
													{row.nameEmployeeKiosk}
												</TableCell>
												<TableCell align="right">
													{row.nicknameEmployeeKiosk}
												</TableCell>
												<TableCell align="right">
													<Button
														onClick={handleClickUpdate(
															row
														)}
													>
														<EditIcon />
													</Button>
													<Button
														onClick={handleClickDelete(
															row
														)}
													>
														<DeleteIcon />
													</Button>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</div>
					<TablePagination
						rowsPerPageOptions={[8, 25, 100]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						backIconButtonProps={{
							'aria-label': 'previous page',
						}}
						nextIconButtonProps={{
							'aria-label': 'next page',
						}}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
}
