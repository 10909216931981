import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch } from "react-redux";
import { menuOption,menuOpen } from "../../../../redux/actions";


export default function ItemSidebar({ option }) {
    const dispatch=useDispatch()
    function handleClick() {
        dispatch(menuOpen(false))
        dispatch(menuOption(option.option))
    }

    return (
        <div>
            <ListItem button onClick={handleClick} aria-haspopup="true">
                <ListItemIcon>
                    {option.icon}
                </ListItemIcon>
                <ListItemText primary={option.title} />
            </ListItem>
        </div>
    );
}