import React  from 'react';
import { Grid } from '@material-ui/core';
import Navbar from './components/Navbar';
import Container from './components/Container';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Login from './components/LoginWindow';
import { useSelector, useDispatch } from 'react-redux';
import { signIn } from './redux/actions';

function App() {
	const dispatch = useDispatch();

	if (sessionStorage.getItem('token')) {
		dispatch(signIn());
	}

	const isLogged = useSelector((state) => state.isLogged);

	const content = (
		<div className="App">
			<Grid container>
				{/* Navbar de arriba  :0 se va a pasar a otro componente después*/}
				<Grid item xs={12}>
					<Navbar />
				</Grid>
				{/* container c: */}
				<Grid item xs={12}>
					<Container />
				</Grid>
				<Grid item xs={12}>
					<Footer />
				</Grid>
			</Grid>
			<Sidebar />
		</div>
	);

	return <div className="App">{isLogged ? content : <Login />}</div>;
}

export default App;
