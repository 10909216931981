import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider } from '@material-ui/core';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import theme from './theme';
import { createStore } from 'redux';
import reducer from './redux/reducer';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { RECAPTCHA_KEY } from './constants';

const store = createStore(
	reducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const history = createBrowserHistory({
	basename: process.env.PUBLIC_URL,
});

ReactDOM.render(
	<MuiThemeProvider theme={theme}>
		<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
			<Provider store={store}>
				<App />
			</Provider>
		</GoogleReCaptchaProvider>
	</MuiThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
