import React from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import GroupIcon from '@material-ui/icons/Group';
import ChartIcon from '@material-ui/icons/InsertChart';
import StoreIcon from '@material-ui/icons/Storefront';
import ItemSidebar from './ItemSidebar';

const data = [
	{
		title: 'Caja',
		option: 'cashier',
		icon: <StoreIcon />,
	},
	{
		title: 'Usuarios',
		option: 'employees',
		icon: <GroupIcon />,
	},
	{
		title: 'Ventas',
		option: 'orders',
		icon: <ChartIcon />,
	},
];

export default function ListSidebar() {
	return (
		<List component="nav" aria-label="">
			{data.map((option) => (
				<>
					<ItemSidebar option={option} key={option.title} />
					<Divider />
				</>
			))}
		</List>
	);
}
