import { SERVER_BASE_URL,SERVER_BASE_URL_KIOSK } from '../constants';
/**
 * Servicio para ingresar a la tienda
 * @param {String} email Correo electrónico
 * @param {String} password Contraseña
 * @param {String} confirmpassword Contraseña que debe coincidir con la anterior
 * @param { String } token token generado por recaptcha
 */
export const logIn = async (email, password, token) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}logInRegister`, {
			method: 'POST',
			body: JSON.stringify({ email, password, token }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			if (!json.token) {
				return false;
			}
			sessionStorage.setItem('token', json.token);
			return true;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

/**
 * Servicio para ingresar a la tienda
 * @param {String} name usuario
 * @param {String} pin Contraseña
 */
export const logInEmployee = async (name, pin) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}logInEmployee`, {
			method: 'POST',
			body: JSON.stringify({ name, pin }),
			headers: {
				token: sessionStorage.getItem('token'),
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();
		if (!json.token) {
			return false;
		}
		return json;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const recoverPasswordEmail = async (email, token) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}recoverPasswordEmail`, {
			method: 'POST',
			body: JSON.stringify({ email, token }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			sessionStorage.setItem('token', json.token);
			return true;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const recoverPassword = async (password, confirmPassword, token) => {
	try {
		const response = await fetch(`${SERVER_BASE_URL}recoverPassword`, {
			method: 'POST',
			body: JSON.stringify({ password, confirmPassword }),
			headers: {
				'Content-Type': 'application/json',
				token: token,
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			sessionStorage.setItem('token', json.token);
			return true;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};
