import React, { PureComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import OrdersTable from './OrdersTable';
import { getOrders } from '../../../services/sales';
import socketIOClient from 'socket.io-client';
import { SOCKET_IP } from '../../../constants';
import PaymentDialog from './OrderDialogs/PaymentDialog';

class Orders extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			sales: [],
			paymentDialog: {
				open: false,
				selling: {
					totalSelling: 0,
				},
			},
		};
	}
	socket = socketIOClient(SOCKET_IP, {
		query: {
			token: sessionStorage.getItem('token'),
		},
		path: '/ws/socket/',
	});
	componentDidMount() {
		this.getData();
		this.socket.on('updatedSale', ({ selling }) => {
			let { sales } = this.state;
			sales = sales.map(function (sale) {
				return sale.idSelling === selling.idSelling ? selling : sale;
			});
			this.setState({ sales });
		});
		this.socket.on('insertedSale', ({ selling }) => {
			let { sales } = this.state;
			sales = sales.push(selling);
			this.setState({ sales });
		});
	}

	async getData() {
		try {
			const { values } = await getOrders();
			this.setState({ sales: values });
		} catch (error) {
			console.error(error);
		}
	}
	componentWillUnmount() {
		this.socket.disconnect();
	}

	setSelling(selling, open) {
		console.log('selling from orders', {
			paymentDialog: { ...this.state.paymentDialog, selling },
		});
		this.setState({
			paymentDialog: {
				...this.state.paymentDialog,
				selling,
				open,
			},
		});
	}

	toglePaymentDialog(open) {
		this.setState({ paymentDialog: { ...this.state.paymentDialog, open } });
	}

	render() {
		const { sales } = this.state;
		return (
			<>
				<Typography variant="h4">Caja</Typography>
				<Grid container>
					<Grid item xs={12}>
						<OrdersTable
							data={sales}
							toglePaymentDialog={this.toglePaymentDialog.bind(
								this
							)}
							setSelling={this.setSelling.bind(this)}
						/>
					</Grid>
				</Grid>
				<PaymentDialog
					open={this.state.paymentDialog.open}
					selling={this.state.paymentDialog.selling}
					toglePaymentDialog={this.toglePaymentDialog.bind(this)}
				/>
			</>
		);
	}
}

export default Orders;
