import React from 'react';
import MaterialTable from 'material-table';
import OrderDetail from './orderDetail';
import ActionsCol from './ActionsCol';
import { SELLING_STATUS } from '../../../../constants';

export default function OrdersTable({ data, toglePaymentDialog, setSelling }) {
	const columns = [
		{ title: 'Cliente', field: 'nameClientSelling' },
		{ title: 'Fecha de compra', field: 'date', type: 'datetime' },
		{
			title: 'Estatus',
			field: 'status',
			lookup: {
				[SELLING_STATUS.OUTSTANDING]: 'Sin pago',
				[SELLING_STATUS.IN_PROGRESS]: 'En progreso',
				[SELLING_STATUS.PENDING]: 'Pendiente',
				[SELLING_STATUS.REQUESTED]: 'Solicitado',
				[SELLING_STATUS.FINISHED]: 'Terminado',
			},
		},
		{
			title: 'Total',
			field: 'totalSelling',
			type: 'currency',
			headerStyle: {
				textAlign: 'right',
			},
		},
		{
			title: 'Abono',
			field: 'payments[0].quantityPayment',
			type: 'currency',
			headerStyle: {
				textAlign: 'right',
			},
		},
		{
			title: 'Saldar',
			field: 'payments[1].quantityPayment',
			type: 'currency',
			headerStyle: {
				textAlign: 'right',
			},
			render: ({ totalSelling, payments }) => {
				const [advancePayment, remainder] = payments;
				const toPay =
					totalSelling -
					(advancePayment?.quantityPayment +
						remainder?.quantityPayment || 0);
				return `$${toPay.toFixed(2)}`;
			},
		},
		{
			title: 'Acciones',
			field: 'status',
			render: (rowData) => (
				<ActionsCol
					selling={rowData}
					toglePaymentDialog={toglePaymentDialog}
					setSelling={setSelling}
				/>
			),
		},
	];

	return (
		<MaterialTable
			options={{
				toolbar: true,
				showTitle: false,
			}}
			localization={{
				toolbar: {
					searchTooltip: 'Busqueda',
					searchPlaceholder: 'Busqueda',
				},
				pagination: {
					labelRowsSelect: 'Columnas',
					labelDisplayedRows: '{from}-{to} de {count}',
				},
			}}
			columns={columns}
			data={data}
			detailPanel={(rowData) => <OrderDetail data={rowData.products} />}
		/>
	);
}
