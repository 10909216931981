import { SERVER_BASE_URL_KIOSK } from '../constants';

export const columns = [
	{ label: 'Usuario', id: 'name', minWidth: 100, align: 'left' },
	{ label: 'Rol', id: 'rol', minWidth: 100, align: 'right' },
	{ label: 'Acciones', id: 'actions', minWidth: 0, align: 'right' },
];
//Datos para el datatable
export const rows2 = [
	{
		id: 1,
		nameKioskClient: 'Farmacia Guadalajara',
		nameClient: 'Juanito Banana',
		email: 'jb@mail.com',
		celphone1Client: '4434026232',
		rfcClient: 'ASDQWOJI212',
		streetClient: 'Acerina',
		extNumberClient: '23',
		zipcodeClient: '58130',
		suburbClient: 'Industrial',
		date: '30/10/1997',
		status: '1',
	},
	{
		id: 2,
		nameKioskClient: 'Sears',
		nameClient: 'Miguel Banana',
		email: 'mb@mail.com',
		celphone1Client: null,
		rfcClient: 'ASDQWOJI212',
		streetClient: 'Paseo del Abedul',
		extNumberClient: '396',
		zipcodeClient: '58110',
		suburbClient: 'Prados Verdes',
		date: '09/07/2003',
		status: '0',
	},
];

export const getEmployees = async function () {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}employees`, {
			headers: {
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();

		return json;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const employeeInsert = async function (data) {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}employeeInsert`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			return json;
		}
	} catch (error) {
		console.log(error);
		return [];
	}
};
export const employeeDelete = async function (data) {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}employeeDelete`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		const json = await response.json();
		return json;
	} catch (error) {
		console.log(error);
		return false;
	}
};
export const employeeUpdate = async function (data) {
	try {
		const response = await fetch(`${SERVER_BASE_URL_KIOSK}employeeUpdate`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				token: sessionStorage.getItem('token'),
			},
		});
		if (!response.ok) {
			throw Error(response.statusText);
		} else {
			const json = await response.json();
			return json;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};
