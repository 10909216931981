/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../Copyright';
import { validateEmail, validatePassword } from '../../../services/validations';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
	LinearProgress,
	Typography,
	Button,
	TextField,
	Link,
	Avatar,
	FormControlLabel,
	Checkbox,
	Box,
	Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	links: {
		cursor: 'pointer',
	},
}));

export default function LogIn({
	handleNoUserAcount,
	handleOnSuccess,
	handleRememberMe,
}) {
	const classes = useStyles();
	const [password, setPassword] = useState('');
	const [validPassword, setValidPassword] = useState(true);
	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(true);
	const [valid, setValid] = useState(true);
	const [loading, setLoading] = useState(false);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleOnChangeEmail = (e) => {
		setEmail(e.target.value);
		setValidEmail(validateEmail(e.target.value));
	};
	const handleOnChangePassword = (e) => {
		setPassword(e.target.value);
		setValidPassword(validatePassword(e.target.value));
	};

	const handleOnSubmit = async () => {
		//validar campos
		setValidEmail(validateEmail(email));
		setValidPassword(validatePassword(password));
		setValid(validEmail && validPassword);
		if (!(validateEmail(email) && validatePassword(password))) {
			return;
		}
		//iniciar cargando
		setLoading(true);
		//llamar al ws
		const token = await executeRecaptcha('login');
		handleOnSuccess(email, password, token, setLoading);
	};

	return (
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				Login
			</Typography>
			{loading ? (
				<div className={classes.form}>
					<LinearProgress />
				</div>
			) : (
				<form className={classes.form}>
					<TextField
						value={email}
						error={!validEmail}
						variant="standard"
						margin="normal"
						required
						helperText={
							validEmail
								? null
								: 'favor de introducir un correo válido'
						}
						fullWidth
						id="email"
						label="Correlo electrónico"
						name="email"
						autoComplete="email"
						autoFocus
						onChange={handleOnChangeEmail}
					/>
					<TextField
						value={password}
						error={!validPassword}
						variant="standard"
						margin="normal"
						required
						helperText={
							validPassword
								? null
								: `la contraseña tiene que tener una longitud minima de 8, 
								contener al menos un numero, una letra minuscula, 
								una letra mayuscula y un símbolo`
						}
						fullWidth
						name="password"
						label="Contraseña"
						type="password"
						onChange={handleOnChangePassword}
						id="password"
						autoComplete="current-password"
					/>
					<FormControlLabel
						control={
							<Checkbox
								value="remember"
								color="primary"
								onChange={handleRememberMe}
							/>
						}
						label="Recuerdame"
					/>
					{!valid ? (
						<Typography color="error">
							Favor de validar los campos
						</Typography>
					) : null}
					<Button
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={handleOnSubmit}
					>
						Iniciar sesión
					</Button>
					<Grid container>
						<Grid item xs>
							<Link
								onClick={handleNoUserAcount}
								className={classes.links}
								variant="body2"
							>
								¿Olvidaste tu contraseña?
							</Link>
						</Grid>
						<Grid item>
							<Link
								onClick={handleNoUserAcount}
								className={classes.links}
								variant="body2"
							>
								No tienes una cuenta con nosotros?
							</Link>
						</Grid>
					</Grid>
					<Box mt={5}>
						<Copyright />
					</Box>
				</form>
			)}
		</div>
	);
}
