import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SERVER_BASE_URL } from '../../../../constants';

export default function OrderDetail({data}){

    const styles={
        articleImage:{
            width: "100%",
            height: "100%",
            maxWidth: "300px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            borderBottom: "solid gray 1px",
            borderRight: "solid gray 1px",
        },
        articleContainer:{
            backgroundColor: "#FFF", 
            width:"100%",
            borderTop: "solid black 2px",
            borderBottom: "solid black 1px",
        },
        nameArticle:{
            borderBottom: "solid gray 1px",
            backgroundColor: "lightgray", 
        },
        black:{
            fontWeight: "bold"
        },
        borders:{
            border: "solid gray 1px",
        },
        descTitle:{
            fontWeight: "bold",
            borderBottom: "solid gray 1px",
        }
    }

    return (
        <Grid container>
            {data.map((article)=>
                <div key={article.idArticle} style={styles.articleContainer}>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"} style={styles.nameArticle}>{article.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={4} style={styles.borders}>
                                <div>
                                    <img alt={article.title} style={styles.articleImage} src={SERVER_BASE_URL + article.image}/>
                                    <span style={styles.black}>Precio: </span>${article.price.toFixed(2)}
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant={"subtitle1"} style={styles.descTitle}>Descripción del artículo</Typography>
                                {article.description}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Grid>
    )
}