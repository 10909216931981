const initialState = {
	userName: '',
	rol: '',
	token: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'LOGIN_EMPLOYEE':
			return action.payload;
		case 'SIGN_OUT':
			return true;
		default:
			return state;
	}
};
