import React, { useState } from 'react';
import { Grid, Paper, TextField, Typography, Button } from '@material-ui/core';
import { logInEmployee } from '../../../services/login';
import { loginEmployee } from '../../../redux/actions';
import { useDispatch } from 'react-redux';

export default function EmployeeMw({ children }) {
	const [isLogged, setIsLogged] = useState(false);
	const [employee, setEmployee] = useState({
		user: '',
		password: '',
	});
	const [employeeError, setEmployeeError] = useState({
		user: false,
		password: false,
	});
	const dispatch = useDispatch();

	const handleOnChange = (e) => {
		const { name, value } = e.target;
		if (!value) {
			setEmployeeError({ ...employeeError, [name]: true });
		} else {
			setEmployeeError({ ...employeeError, [name]: false });
		}
		setEmployee({ ...employee, [name]: value });
	};
	const handleOnLogin = async () => {
		const response = await logInEmployee(employee.user, employee.password);
		if (!response.token) {
			return setEmployeeError({
				user: true,
				password: true,
			});
		}
		dispatch(
			loginEmployee(
				response.user.nameEmployeeKiosk,
				response.user.rol,
				response.token
			)
		);
		return setIsLogged(true);
	};

	return (
		<>
			{isLogged ? (
				children
			) : (
				<Grid container justify="center" alignItems="center">
					<Grid item xs={6} md={4}>
						<Paper style={{ padding: 20 }}>
							<Typography variant="h5">
								Entra como cajero
							</Typography>
							<form autoComplete="off">
								<TextField
									value={employee.user}
									error={employeeError.user}
									variant="standard"
									margin="normal"
									autoFocus
									autoComplete="off"
									required
									onChange={handleOnChange}
									helperText={
										employeeError.user &&
										`El usuario que ingresaste no es valido.`
									}
									fullWidth
									name="user"
									label="Usuario"
									type="password"
									id="password"
								/>
								<TextField
									value={employee.password}
									error={employeeError.password}
									variant="standard"
									margin="normal"
									autoComplete="off"
									required
									onChange={handleOnChange}
									helperText={
										employeeError.password &&
										`La contraseña que ingresaste no es valida.`
									}
									fullWidth
									name="password"
									label="Contraseña"
									type="password"
									id="password"
								/>
								<Typography variant="caption" color="error">
									{(employeeError.user ||
										employeeError.password) &&
										'Favor de validar los campos.'}
								</Typography>
								<Button
									variant="contained"
									color="primary"
									onClick={handleOnLogin}
								>
									Ingresar
								</Button>
							</form>
						</Paper>
					</Grid>
				</Grid>
			)}
		</>
	);
}
