export const loginEmployee = (userName, rol, token) => ({
    type:'LOGIN_EMPLOYEE',
    payload:{userName, rol, token}
});

export const open = (idCategory, idSubcategory) => {
	return {
		type: 'OPEN',
		payload: { idCategory, idSubcategory },
	};
};

export const change = (option) => {
	return {
		type: 'CHANGE',
		payload: option,
	};
};

export const menuOption = (option) => {
	return {
		type: 'MENU_OPTION',
		payload: option,
	};
};

export const menuOpen = (open) => {
	return {
		type: 'MENU_OPEN',
		payload: open,
	};
};

export const signIn = (recipes) => {
	return {
		type: 'SIGN_IN',
	};
};
