import React from 'react';
import { SELLING_STATUS } from './../../../../constants';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

export default function ActionsCol({
	selling,
	setSelling,
}) {
	const theme = useTheme();
	const { status, payments, totalSelling } = selling;
	const openOutstandingModal = () =>{
		const dialogOpen=true
		setSelling(selling,dialogOpen)
	}

	const openDeliverModal = () => {};

	const openSeconPayment = () => {};
	const totalPayed =
		payments[0]?.quantityPayment + payments[1]?.quantityPayment;

	const StatusButton = () => {
		if (status === SELLING_STATUS.OUTSTANDING) {
			return (
				<Button
					variant="contained"
					color="primary"
					onClick={openOutstandingModal}
				>
					Abonar
				</Button>
			);
		}

		if (status === SELLING_STATUS.FINISHED) {
			return (
				<Button
					variant="contained"
					style={{ backgroundColor: theme.palette.success.main }}
					onClick={openDeliverModal}
				>
					entregar
				</Button>
			);
		}

		if (status === SELLING_STATUS.CANCELED) {
			return (
				<Button variant="contained" disabled>
					cancelado
				</Button>
			);
		}
		if (totalPayed < totalSelling) {
			return (
				<Button
					variant="contained"
					color="secondary"
					onClick={openSeconPayment}
				>
					SALDAR
				</Button>
			);
		}
		if (status === SELLING_STATUS.FINISHED) {
			return (
				<Button
					variant="contained"
					style={{ backgroundColor: theme.palette.success.main }}
					onClick={openDeliverModal}
				>
					entregar
				</Button>
			);
		}
		return (
			<Button variant="contained" disabled>
				sin acción
			</Button>
		);
	};
	return <>{StatusButton()}</>;
}
