import { createMuiTheme } from "@material-ui/core";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#E77226',
            
        },
        secondary: {
            main: '#527DDF'
        },
        success:{
            main: '#4caf50'
        }

    }
})

export default theme