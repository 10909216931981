import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Button,
	Grid,
	Typography,
} from '@material-ui/core';
import { validateNumber } from '../../../../services/validations';
import { firstPayment } from '../../../../services/sales';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function PaymentDialog({ open, selling, toglePaymentDialog }) {
	const { totalSelling, idSelling } = selling;
	const minPayment = totalSelling * 0.75;
	const [payment, setPayment] = useState(minPayment);
	const [paymentError, setPaymentError] = useState(false);
	const [cash, setCash] = useState(0);
	const token = useSelector((state) => state.employee.token);
	const toPay = totalSelling - payment;

	useEffect(() => {
		setPayment(minPayment);
	}, [minPayment]);

	//hanlders
	const handleOnChangePayment = (e) => {
		const value = e.target.value;
		const isValidated = validateNumber(value, minPayment, totalSelling);
		setPaymentError(!isValidated);
		setPayment(value);
	};
	const handleClose = () => toglePaymentDialog(!open);
	const handlePayment = async () => {
		firstPayment(idSelling, payment, token);
		handleClose()
	};

	const handlePayAll = async () => {
		firstPayment(idSelling, totalSelling, token);
		handleClose()
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Pagos</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Realiza un abono o el pago completo del pedido.
				</DialogContentText>
				<Grid container>
					<Grid item xs={4}>
						<TextField
							value={payment}
							onChange={handleOnChangePayment}
							error={paymentError}
							required
							label="Abono"
							type="number"
							helperText={paymentError && 'Valor invalido'}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							value={cash}
							onChange={(e) => setCash(e.target.value)}
							error={paymentError}
							label="recibo"
							type="number"
							helperText={paymentError && 'Valor invalido'}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							disabled
							label="cambio"
							type="number"
							value={cash - payment > 0 ? cash - payment : 0}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h5" display="block">
							Total
						</Typography>
						<Typography variant="h6" display="block">
							${totalSelling.toFixed(2)}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h5" display="block">
							Abono minimo
						</Typography>
						<Typography variant="h6" display="block">
							${minPayment.toFixed(2)}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h5" display="block">
							A saldar
						</Typography>
						<Typography variant="h6" display="block">
							${(toPay > 0 ? toPay : 0).toFixed(2)}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={handleClose}
					color="secondary"
				>
					Cancelar
				</Button>
				<Button
					variant="contained"
					onClick={handlePayAll}
					color="primary"
				>
					Pagar todo
				</Button>
				<Button
					variant="contained"
					onClick={handlePayment}
					color="primary"
				>
					Abonar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
