import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { Grid, Button } from '@material-ui/core';
import { employeeUpdate } from '../../../../../services/employee';
import NumberFormat from 'react-number-format';
import { validateNumber,validateText } from "../../../../../services/validations";

class FormEditEmployee extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.data.nameEmployeeKiosk,
			pin: '',
			nickName: this.props.data.nicknameEmployeeKiosk,
			error:{
				name:false,
				pin:false,
				nickName:false,
			}
		};
	}

	render() {
		const { handleClose, classes, change } = this.props;

		const handleChange = (event) => {
			const val = event.target.value;
			this.setState({ [event.target.name]: val });
		};

		const handleSubmit = async () => {
			//obtener valores actualizados
			const { name, pin, nickName } = this.state;
			//validar campos
			let validated=true
			const validations={
				name:false,
				nickName:false,
				pin:false,
			}
			if (!validateText(name,1,45)) {
				validated=false
				validations.name=true
			}
			if (!validateText(nickName,0,45)) {
				validated=false
				validations.nickName=true
			}

			if (!validateNumber(pin,1000,9999)) {
				validated=false
				validations.pin=true
			}
			if (!validated) {
				this.setState({error:{...validations}})
				return
			}


			const data = {
				name,
				pin,
				nickName,
				id: this.props.data.id,
			};

			try {
				const response = await employeeUpdate(data);
				if (!response) {
					throw new Error('No response');
				}
				handleClose();
				change();
			} catch (error) {
				console.error(error);
			}
		};

		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<div className={classes.subtitles}>
						Datos generales del empleado
					</div>
				</Grid>
				<Grid item xs={6}>
					<TextField
						required
						name="name"
						error={this.state.error.name}
						label="Nombre de  empleado"
						value={this.state.name}
						onChange={handleChange}
						margin="normal"
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						name="nickName"
						error={this.state.error.nickName}
						label="Rol"
						value={this.state.nickName}
						onChange={handleChange}
						margin="normal"
						fullWidth
					/>
				</Grid>
				<Grid item xs={6}>
					<NumberFormat
						format='####'
						name="pin"
						error={this.state.error.pin}
						required
						customInput={TextField}
						label="Pin de entrega"
						value={this.state.pin}
						onChange={handleChange}
						margin="normal"
						fullWidth
					/>
				</Grid>

				<Grid item container justify="flex-end">
					<Grid item>
						<div>{this.state.formError}</div>
					</Grid>
					<Grid item>
						<Button onClick={handleClose} color="primary">
							Cancelar
						</Button>
					</Grid>
					<Grid item>
						<Button
							onClick={handleSubmit}
							variant="contained"
							color="primary"
						>
							Guardar
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default FormEditEmployee;
