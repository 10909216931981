import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { signIn } from '../../redux/actions';
import { logIn } from '../../services/login';
import Login from '../miscellaneous/LogIn';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}));

export default function SignInSide() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const handleOnSubmit = async (email, password, token, setLoading) => {
		const success = await logIn(email, password, token);
		if (success) {
			dispatch(signIn());
			return;
		}
		setLoading(false);
	};
	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
			>
				<Login handleOnSuccess={handleOnSubmit} />
				<div style={{padding:20}}>
					<Typography variant="caption" align={'center'}>
						{'This site is protected by reCAPTCHA and the Google '}
						<a href="https://policies.google.com/privacy">
							{'Privacy Policy'}
						</a>
						{' and '}
						<a href="https://policies.google.com/terms">
							{' Terms of Service '}
						</a>
						{'apply'}
					</Typography>
				</div>
			</Grid>
		</Grid>
	);
}
