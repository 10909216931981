import React from 'react';
import { makeStyles, DialogContent, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import FormEditEmployee from './FormEditEmployee';

const useStyles = makeStyles((theme) => ({
	subtitles: {
		fontWeight: 'bold',
	},
	dialogTitle: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
	},
}));

export default function DialogEmployee({
	open,
	handleClose,
	row,
	change,
}) {
	const classes = useStyles();
	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			fullWidth={true}
			maxWidth={'md'}
		>
			<DialogTitle
				id="customized-dialog-title"
				className={classes.dialogTitle}
			>
				Modificar ususario
			</DialogTitle>
			<DialogContent dividers>
				<FormEditEmployee
					classes={classes}
					handleClose={handleClose}
					data={row}
					change={change}
				/>
			</DialogContent>
		</Dialog>
	);
}
