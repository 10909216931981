import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExitIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import { Grid, AppBar, Toolbar, Link } from "@material-ui/core";
import { menuOpen } from '../../redux/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        paddingRight: 10,
    },
    image: {
        
		width: '100%',
		maxWidth: 135,
		maxHeight: 50,
    },
    navBar: {
        background: "linear-gradient(90deg, rgba(25,30,89,1) 0%, rgba(181,78,132,1) 55%, rgba(231,114,38,1) 100%)",
    }
}));

export default function Navbar() {

    const classes = useStyles();
    const dispatch = useDispatch()
    const toggleMenu = () => {
        dispatch(menuOpen(true))
    }
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.navBar}>
                <Toolbar>
                    <MenuIcon onClick={toggleMenu} />
                    <Grid container justify="flex-end" alignItems="center">
                    <Link to="/">
								<img
									src={"./logoTecnicamara-w.png"}
									alt="logo"
									className={classes.image}
								/>
							</Link>
                        <Typography variant="h6" className={classes.title}>
                            Juanito Banana
                        </Typography>
                        <ExitIcon />
                    </Grid>
                </Toolbar>
            </AppBar>

        </div>
    )
};
