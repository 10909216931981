export const SERVER_BASE_URL_KIOSK ='https://tecnicamara.com/ws/kiosk/';
export const SERVER_BASE_URL = 'https://tecnicamara.com/ws/public/';
export const RECAPTCHA_KEY = '6Lc9DvMUAAAAAEyq-ZpfpHxA6kkQNiKXg3T4KBNs';
export const SOCKET_IP='https://tecnicamara.com/';

export const SELLING_STATUS = {
	CANCELED:0,
	REQUESTED:1,
	IN_PROGRESS:2,
	FINISHED:3,
    PENDING:4,
    OUTSTANDING:5,//PENDIENTE DE PAGO
}
