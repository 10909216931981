import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { useSelector } from "react-redux";
import Employees from "./Employees";
import Orders from "./Orders";
import EmployeeMw from './MiddleWares/EmployeeMw';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight:'90vh'
    },
}));

const selectContent = (menuItem) => {
    switch (menuItem) {
        case 'employees':
            return <Employees />
        case 'cashier':
            return <EmployeeMw><Orders/></EmployeeMw>
        default:
            return <div>Not found</div>
    }
}

//dependiendo del menuItem mostrar un contenido diferente
const Container = () => {
    const classes = useStyles();
    return <Grid container direction="row" className={classes.root}>
        {selectContent(useSelector(state => state.menu.option))}
    </Grid>
}



export default Container